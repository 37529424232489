import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgInsertionCover from "../../assets/images/journey/mars-orbit-insertion/insertion-cover.png"

const CruisePage = () => (
  <Layout>
    <SEO title="Mars Orbit Insertion" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40" style={{borderBottom: "5px solid #88370C"}}>
        <img src={imgInsertionCover} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
                <div>
                  <h2 className="relative">
                  MOI (MARS ORBIT INSERTION)
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  During the Mars Orbit Insertion phase, the Ground and Space Segments are kept to a minimum while the team focuses on safely entering a capture orbit at Mars. Nearly half of the fuel is spent to slow the Hope Probe down enough to capture Mars’ orbit. The fuel burn (firing the Delta V thrusters) will last approximately 30 mins and reduces the speed of the spacecraft from over 121,000 km/h to approximately 18,000 km/h.
                  </p>
                  <p>
                  As radio signals from Mars takes 13 to 26 minutes to travel to Earth, the team will not be able to intervene with the Hope Probe. The whole operation is completed 100% autonomously. Once the Mars Orbit Insertion is completed, the Hope Probe will ‘go dark’ – eclipsed by Mars. Only when the spacecraft emerges from the dark side of Mars will communication be re-established, and the team will only then be certain that the manoeuvre has succeeded. The first contact with the observatory after MOI will likely come from the ground station in Spain.
                  </p>
                  <p>
                  Over the next few months, the instruments onboard the Hope Probe are tested, and manoeuvres are performed to get the Probe into the correct position for the science observations.
                  </p>
                </div>
            </div>
                    
          </div>  
      </section>
    </main>
  </Layout>
)

export default CruisePage
